import { useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';
import { PageMessage } from '@netfront/ui-library';
import { NextPage } from 'next';

const Error404Page: NextPage = () => {
  const { isDomainReady } = useDomain();
  const { getBaseUrl: identityBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });

  const [identityUrl, setIdentityUrl] = useState<string>('');

  useEffect(() => {
    if (!isDomainReady) {
      return;
    }

    setIdentityUrl(identityBaseUrl());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDomainReady]);
  return (
    <PageMessage
      contactUrl="/contact"
      dashboardUrl={`${identityUrl}/dashboard`}
      headerText="page not found!"
      message="Looks like the page you are looking for isn't here. Don't worry, you can jump back to a safe place below"
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default Error404Page;
